<template>
  <div style="width: 100%" class="labels-block">
    <div class="pa-5" style="width: 100%; max-width: 700px; min-width: 200px;">
      <v-row align="center" justify="space-between" class="ma-0 mb-4">
        <h4>Labels</h4>
        <v-btn
          v-if="labels.length"
          class="upgrade mt-3 mt-sm-0"
          color="primary"
          elevation="0"
          @click="createEditDialog = true"
        >
          Create label
        </v-btn>
      </v-row>
      <div class="labels-wrapper">
        <v-card
          v-if="labels.length || listLoading"
          :loading="listLoading"
          class="mx-auto w-100"
        >
          <v-list>
            <v-list-item v-for="label in labels" :key="label.uuid"
                         :value="label"
                         color="primary"
                         :class="{ active: activeLabel === label.uuid }"
            >
              <v-list-item-content class="cursor-p" @click="setActiveLabel(label.uuid)">
                {{ label.label }}
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex items-center">
                  <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="text-none d-flex align-center px-0"
                        v-bind="attrs"
                        v-on="on"
                        @click="openCreateEditModal(label)"
                      >
                        <v-icon size="20" color="#444746">mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom color="dark">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="text-none d-flex align-center px-0"
                        v-bind="attrs"
                        v-on="on"
                        @click="openDeleteModal(label)"
                      >
                        <v-icon size="20" color="#444746">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
        <div v-else class="text-center mt-10">
          <p class="grey--text">You have no labels. <a @click="createEditDialog = true">Click here to create a new one</a></p>
        </div>
      </div>
    </div>
    <div class="pa-5 contacts-block" style="width: 100%; min-width: 200px;" v-if="activeLabel">
      <v-card
        v-if="contacts.length !== 0 || loadingContacts"
        :loading="loadingContacts"
        class="mx-auto w-100"
      >
        <v-list>
          <v-list-item v-for="item in contacts" :key="item.uuid">
            <v-list-item-content class="cursor-p">
              <div class="mb-1">
                {{ item.first_name || '' }} {{ item.last_name || '' }}
              </div>
              <div>
                {{ item.phone_number }}
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex items-center">
                <v-tooltip bottom color="dark">
                  <template v-slot:activator="{ on, attrs }">
                    <v-img v-bind="attrs" v-on="on" src="../assets/svg/fi_delete.svg" class="cursor-p white--text" @click="openModalRemoveLabelContact(item)" />
                  </template>
                  <span>Remove</span>
                </v-tooltip>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <div v-else class="text-center mt-10">
        <p class="grey--text">You have no contacts with selected label</p>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Delete Label
          <v-spacer></v-spacer>
          <v-btn @click="closeDeleteModal" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete <span class="font-weight-bold">"{{ selectedLabel.label }}</span>"?
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeDeleteModal"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="deleteLabelHandler"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createEditDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          {{ selectedLabel.uuid ? 'Edit' : 'Create' }} Label
          <v-spacer></v-spacer>
          <v-btn @click="closeCreateEditModal" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="selectedLabel.label"
              :rules="labelRules"
              dense
              label="Label"
              outlined
              @keypress="onlyLettersAndNumbers"
              @keypress.enter.prevent="createEditLabelHandler"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeCreateEditModal"
            >Cancel
          </v-btn>
          <v-btn
            :disabled="!selectedLabel.label || !selectedLabel.label.trim()"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="createEditLabelHandler"
          >
          {{ selectedLabel.uuid ? 'Save' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalLabelContact.show" width="500">
      <v-card>
        <v-card-title class="body-1">
          Remove Label
          <v-spacer></v-spacer>
          <v-btn @click="closeModalRemoveLabelContact" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Are you sure you want to remove label from this contact <span class="font-weight-bold">{{ modalLabelContact.data.phone_number }}</span>?
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeModalRemoveLabelContact"
          >Cancel
          </v-btn>
          <v-btn
            :loading="modalLabelContact.loading"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="removeLabelContactMethod"
          >Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Labels',
  data: () => ({
    deleteDialog: false,
    selectedLabel: {},
    createEditDialog: false,
    activeLabel: null,
    loadingContacts: false,
    fetching: false,
    contacts: [],
    modalLabelContact: {
      data: {},
      show: false,
      loading: false
    }
  }),
  computed: {
    ...mapGetters('labels', ['labels', 'listLoading']),
    labelRules() {
      return [
        value => !!value || 'Label field is required'
      ]
    },
    isEdit() {
      return this.selectedLabel.uuid
    }
  },
  created () {
    this.getLabels()
  },
  methods: {
    ...mapActions('contacts', ['getContactsById', 'updateContact']),
    ...mapActions('labels', ['getLabels', 'deleteLabel', 'createLabel', 'editLabel']),
    ...mapActions(['showAlert']),
    async setActiveLabel(id) {
      this.activeLabel = this.activeLabel === id ? null : id
      if (!this.activeLabel) return
      try {
        this.loadingContacts = true
        const res = await this.getContactsById(this.activeLabel)
        this.contacts = res?.data || []
      } catch (err) {
        this.showAlert({ error: 'Sorry, there was something wrong while loading contacts' })
      } finally {
        this.loadingContacts = false
      }
    },
    openDeleteModal(label) {
      this.selectedLabel = label
      this.deleteDialog = true
    },
    openCreateEditModal(label) {
      this.selectedLabel = { ...label }
      this.createEditDialog = true
    },
    closeDeleteModal() {
      this.selectedLabel = {}
      this.deleteDialog = false
    },
    async deleteLabelHandler() {
      if (this.fetching) return
      try {
        this.fetching = true
        const status = await this.deleteLabel(this.selectedLabel.uuid)

        if (status) {
          this.showAlert({ message: `Label "${this.selectedLabel.label}" was successfully deleted` })
        }

        this.closeDeleteModal()
        this.getLabels()
      } catch (err) {
        this.showAlert({ error: 'Sorry, there was something wrong while deleting label' })
      } finally {
        this.fetching = false
      }
    },
    closeCreateEditModal() {
      this.$refs.form.resetValidation()

      this.createEditDialog = false
      this.selectedLabel = {}
    },
    openModalRemoveLabelContact(item) {
      this.modalLabelContact.data = item
      this.modalLabelContact.show = true
    },
    closeModalRemoveLabelContact() {
      this.modalLabelContact.show = false
      this.modalLabelContact.data = {}
    },
    async removeLabelContactMethod() {
      if (this.modalLabelContact.loading) return
      try {
        this.modalLabelContact.loading = true
        const payload = {
          phone_number: this.modalLabelContact.data.phone_number,
          label_uuid: [],
          first_name: this.modalLabelContact.data.first_name,
          last_name: this.modalLabelContact.data.last_name
        }
        const res = await this.updateContact({
          uuid: this.modalLabelContact.data.uuid,
          payload
        })
        if (res) {
          this.showAlert({ message: 'Label was successfully removed from contact' })
          this.getContactsById(this.activeLabel)
          this.closeModalRemoveLabelContact()
        }
      } catch (err) {
        this.showAlert({ error: 'Sorry, there was something wrong' })
      } finally {
        this.modalLabelContact.loading = false
      }
    },
    async createEditLabelHandler() {
      if (this.fetching) return
      try {
        // console.log(this.$refs.form, 'this.$refs.form')
        this.fetching = true
        const isValid = await this.$refs.form.validate()

        if (!isValid) return
        this.selectedLabel.uuid
          ? await this.editLabel({ id: this.selectedLabel.uuid, label: this.selectedLabel.label })
          : await this.createLabel({ label: this.selectedLabel.label })

        this.showAlert({ message: `Label was successfully ${this.isEdit ? 'edited' : 'created'}` })

        this.closeCreateEditModal()
        this.getLabels()
      } catch (err) {
        // this.showAlert({ error: `Sorry, there was something wrong while   ${this.isEdit ? 'editing' : 'creating'} label` })
        const error = (Array.isArray(err.message)) ? err.message[0] : err.error
        this.showAlert({ error })
      } finally {
        this.fetching = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.labels-wrapper {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}
.active {
  background-color: rgba(86, 87, 175, 0.63);
  color: white!important;
  fill: white!important;
}
.labels-block {
  display: flex;
  width: 100%;
  max-height: calc(100vh - 72px);
}
.contacts-block {
  max-width: 300px;
  margin-top: 54px;
}
@media (min-width: 1120px) and (max-width: 1400px) {
  .labels-block {
    flex-wrap: wrap;
  }
  .contacts-block {
    max-width: 700px;
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .labels-block {
    flex-wrap: wrap;
  }
  .contacts-block {
    max-width: 700px;
    margin-top: 0;
  }
}
</style>
